import React from 'react'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'
import { ScreenContentChartHistorical } from 'modules/reports/screens/screen-charts/content/ScreenContentChartHistorical'
import { ScreenContentChartStatistic } from 'modules/reports/screens/screen-charts/content/ScreenContentChartStatistic'
import { ScreenContentChartGeographic } from 'modules/reports/screens/screen-charts/content/ScreenContentChartGeographic'
import { ScreenContentChartFieldCorrelation } from 'modules/reports/screens/screen-charts/content/ScreenContentChartFieldCorrelation'
import { ScreenContentChartMatrixCorrelation } from 'modules/reports/screens/screen-charts/content/ScreenContentChartMatrixCorrelation'
import { ScreenContentChartLocationCorrelation } from 'modules/reports/screens/screen-charts/content/ScreenContentChartLocationCorrelation'
import { ScreenContentChartWaterContamination } from 'modules/reports/screens/screen-charts/content/ScreenContentChartWaterContamination'
import { ScreenContentChartWaterQuality } from 'modules/reports/screens/screen-charts/content/ScreenContentChartWaterQuality'
import { ScreenContentChartIET } from 'modules/reports/screens/screen-charts/content/ScreenContentChartIET'

export const SCREEN_CHARTS_MAIN_SUBMENU_KEY = 'main'

export type ScreenChartsViewTP = 'historical' | 'statistic' | 'geographic' | 'fieldCorrelation' | 'matrixCorrelation' | 'locationCorrelation' | 'waterContamination' | 'waterQuality' | 'iet'

/**
 */
export const ChartsScreenUtils = {

    getSubMenuConfigs(): Array<ScreenSubMenuTP<ScreenChartsViewTP>> {
        return [
            {
                key: SCREEN_CHARTS_MAIN_SUBMENU_KEY,
                title: 'Geral',
                items: [
                    {
                        name: 'Série Histórica',
                        view: 'historical',
                        route: `${PrivateRouter.CHART_HISTORIC_SERIES}`,
                        content: <ScreenContentChartHistorical/>,
                    },
                    {
                        name: 'Estatística',
                        view: 'statistic',
                        route: `${PrivateRouter.CHART_STATISTIC}`,
                        content: <ScreenContentChartStatistic/>,
                    },
                    {
                        name: 'Geográfico',
                        view: 'geographic',
                        route: `${PrivateRouter.GEOGRAPHIC}`,
                        content: <ScreenContentChartGeographic/>,
                    },
                    {
                        name: 'Correlação de Parâmetros',
                        view: 'fieldCorrelation',
                        route: `${PrivateRouter.CORRELATION_FIELDS}`,
                        content: <ScreenContentChartFieldCorrelation/>,
                    },
                    {
                        name: 'Matriz de Correlação',
                        view: 'matrixCorrelation',
                        route: `${PrivateRouter.CORRELATION_MATRIX}`,
                        content: <ScreenContentChartMatrixCorrelation/>,
                    },
                    {
                        name: 'Correlação de Pontos',
                        view: 'locationCorrelation',
                        route: `${PrivateRouter.CORRELATION_LOCATIONS}`,
                        content: <ScreenContentChartLocationCorrelation/>,
                    },
                    {
                        name: 'Contaminação de Água',
                        view: 'waterContamination',
                        route: `${PrivateRouter.WATER_CONTAMINATION}`,
                        content: <ScreenContentChartWaterContamination/>,
                    },
                    {
                        name: 'Qualidade da Água',
                        view: 'waterQuality',
                        route: `${PrivateRouter.WATER_QUALITY}`,
                        content: <ScreenContentChartWaterQuality/>,
                    },
                    {
                        name: 'Índice de Estado Trófico (IET)',
                        view: 'iet',
                        route: `${PrivateRouter.IET}`,
                        content: <ScreenContentChartIET/>,
                    },
                ],
                enabled: true
            },
        ]
    },

}
