import { IQAEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IQAEnum'
import { IETEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IETEnum'

export const ThemeProjectCommon = {

    qualityWaterColors: {
        [IQAEnum.EXCELENT]: '#A2CFFA',
        [IQAEnum.GOOD]: '#BFF8BF',
        [IQAEnum.MEDIUM]: '#F6F9A3',
        [IQAEnum.BAD]: '#FBC287',
        [IQAEnum.VERY_BAD]: '#FC8786',
    },

    ietColors: {
        [IETEnum.ULTRAOLIGOTROPHIC]: '#2466CC',
        [IETEnum.OLIGOTROPHIC]: '#248000',
        [IETEnum.MESOTROPHIC]: '#FDFD01',
        [IETEnum.EUTROPHIC]: '#FACC01',
        [IETEnum.SUPEREUTROPHIC]: '#F41403',
        [IETEnum.HYPEREUTROPHIC]: '#801080',
    },

}
