import React from 'react'
import { WrapperTableBulkActionsCP } from 'submodules/nerit-framework-ui/common/components/table/wrapper-table-bulk-actions/WrapperTableBulkActionsCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import styled from 'styled-components'
import { SubtitleTableWrapperCP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/SubtitleTableWrapperCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { IETChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IETChartRequestDTO'
import { IETTableTP } from 'submodules/nerit-aquatec-sdk/services/charts/types/IETTableTP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import subtitleImg from 'modules/reports/components/tables/table-iet-chart/inner/iet_subtitle.png'
import { IETLabelEnum } from 'submodules/nerit-aquatec-sdk/services/charts/enums/IETEnum'

interface ICPProps {
    data: IETTableTP[]
    filters: IETChartRequestDTO
}

/**
 */
export function TableIETChartCP(props: ICPProps): JSX.Element {

    return (
        <>
            <WrapperTableBulkActionsCP>
                <ButtonExportFromApiCP
                    requestConfig={() => ChartsRequests.getIETChartExport(props.filters)}
                    fileName={'iet'}
                />
            </WrapperTableBulkActionsCP>

            <TableCP<IETTableTP>
                wrappedOnCard={true}
                data={props.data}
                columns={[
                    {
                        title: 'Ponto',
                        render: (text, record) => record.locationName
                    },
                    {
                        title: 'Data',
                        render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITHOUT_TIME)
                    },
                    {
                        title: 'Rio',
                        render: (text, record) => {

                            if (!record.ietRiverValue)
                                return <></>

                            return (
                                <IndexSCP style={{ backgroundColor: ThemeProjectCommon.ietColors[record.ietRiverLabel] }}>
                                    {MaskUtils.applyNumberMask(+record.ietRiverValue, 2)}
                                </IndexSCP>
                            )
                        }
                    },
                    {
                        render: (text, record) => (
                                <IndexSCP style={{ backgroundColor: ThemeProjectCommon.ietColors[record.ietRiverLabel] }}>
                                    {IETLabelEnum[record.ietRiverLabel]}
                                </IndexSCP>
                            )
                    },
                    {
                        title: 'Reservatório',
                        render: (text, record) => {

                            if (!record.ietReservatoryValue)
                                return <></>

                            return (
                                <IndexSCP style={{ backgroundColor: ThemeProjectCommon.ietColors[record.ietReservatoryLabel] }}>
                                    {MaskUtils.applyNumberMask(+record.ietReservatoryValue, 2)}
                                </IndexSCP>
                            )
                        }
                    },
                    {
                        render: (text, record) => (
                            <IndexSCP style={{ backgroundColor: ThemeProjectCommon.ietColors[record.ietReservatoryLabel] }}>
                                {IETLabelEnum[record.ietReservatoryLabel]}
                            </IndexSCP>
                        )
                    },
                ]}
            />

            <SubtitleTableWrapperCP>
                <img src={subtitleImg} style={{ marginBottom: 20 }}/>
            </SubtitleTableWrapperCP>
        </>
    )
}

const IndexSCP = styled.div`
  text-align: center;
  padding: 2px 5px;
  border-radius: 2px;
  color: #fff;
  width: 130px;
`
