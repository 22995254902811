import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { FieldCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/FieldCorrelationChartRequestDTO'
import { GeographicChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/GeographicChartRequestDTO'
import { HistoricChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/HistoricChartRequestDTO'
import { FieldCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/FieldCorrelationChartResponseDTO'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { StatisticChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/StatisticChartRequestDTO'
import { StatisticChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/StatisticChartResponseDTO'
import { MatrixCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/MatrixCorrelationChartRequestDTO'
import { MatrizCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/MatrizCorrelationChartResponseDTO'
import { LocationCorrelationChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/LocationCorrelationChartRequestDTO'
import { LocationCorrelationChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/LocationCorrelationChartResponseDTO'
import { ToxicityChartListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/ToxicityChartListItemResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ToxicityIndexChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/ToxicityIndexChartRequestDTO'
import { IQAChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IQAChartRequestDTO'
import { IQAChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/IQAChartResponseDTO'
import { IETChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/IETChartResponseDTO'
import { IETChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IETChartRequestDTO'

export class ChartsRequests {

    public static CONTROLLER_ROOT = 'charts'
	
    public static GET_HISTORIC_CHART = 'historic-chart'
    public static GET_HISTORIC_CHART_EXPORT = 'historic-chart-export'

    public static GET_STATISTIC_CHART = 'statistic-chart'
    public static GET_STATISTIC_CHART_EXPORT = 'statistic-chart-export'

    public static GET_FIELD_CORRELATION_CHART = 'field-correlation-chart'
    public static GET_FIELD_CORRELATION_CHART_EXPORT = 'field-correlation-chart-export'

    public static GET_LOCATION_CORRELATION_CHART = 'location-correlation-chart'
    public static GET_LOCATION_CORRELATION_CHART_EXPORT = 'location-correlation-chart-export'

    public static GET_MATRIX_CORRELATION_CHART = 'matrix-correlation-chart'
    public static GET_MATRIX_CORRELATION_CHART_EXPORT = 'matrix-correlation-chart-export'
    
	public static GET_GEOGRAPHIC_CHART = 'geopraphic-chart'
	public static GET_GEOGRAPHIC_CHART_EXPORT = 'geopraphic-chart-export'

    public static GET_IQA_CHART = 'iqa-chart'
    public static GET_IQA_CHART_EXPORT = 'iqa-chart-export'

    public static GET_IT_CHART = 'it-chart'
    public static GET_IT_CHART_EXPORT = 'it-chart-export'

	public static GET_IET_CHART = 'iet-chart'
    public static GET_IET_CHART_EXPORT = 'iet-chart-export'

    static getHistoricChart = (dto: HistoricChartRequestDTO): RequestConfigTP<GenericChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_HISTORIC_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getHistoricChartExport = (dto: HistoricChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_HISTORIC_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getFieldCorrelationChart = (dto: FieldCorrelationChartRequestDTO): RequestConfigTP<FieldCorrelationChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_FIELD_CORRELATION_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getFieldCorrelationChartExport = (dto: FieldCorrelationChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_FIELD_CORRELATION_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static getGeographicChart = (dto: GeographicChartRequestDTO): RequestConfigTP<GenericChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_GEOGRAPHIC_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getGeographicChartExport = (dto: GeographicChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_GEOGRAPHIC_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})


    static getStatisticChart = (dto: StatisticChartRequestDTO): RequestConfigTP<StatisticChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_STATISTIC_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getStatisticChartExport = (dto: StatisticChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_STATISTIC_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getLocationCorrelationChart = (dto: LocationCorrelationChartRequestDTO): RequestConfigTP<LocationCorrelationChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_LOCATION_CORRELATION_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getLocationCorrelationChartExport = (dto: LocationCorrelationChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_LOCATION_CORRELATION_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getMatrixCorrelationChart = (dto: MatrixCorrelationChartRequestDTO): RequestConfigTP<MatrizCorrelationChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_MATRIX_CORRELATION_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getMatrixCorrelationChartExport = (dto: MatrixCorrelationChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_MATRIX_CORRELATION_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getIQAChart = (dto: IQAChartRequestDTO): RequestConfigTP<IQAChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IQA_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getIQAChartExport = (dto: IQAChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IQA_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

    static getToxicityChart = (dto: ToxicityIndexChartRequestDTO): RequestConfigTP<ListResponseDTO<ToxicityChartListItemResponseDTO>> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IT_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getToxicityChartExport = (dto: ToxicityIndexChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IT_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static getIETChart = (dto: IETChartRequestDTO): RequestConfigTP<IETChartResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IET_CHART}`,
        method: HttpMethodEnum.GET,
        params: dto
    })

	static getIETChartExport = (dto: IETChartRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${ChartsRequests.CONTROLLER_ROOT}/${ChartsRequests.GET_IET_CHART_EXPORT}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})
}
