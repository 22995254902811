/** Enum do grafico de indice de estado trofico. */
export enum IETEnum {
    ULTRAOLIGOTROPHIC = 'ULTRAOLIGOTROPHIC',
    OLIGOTROPHIC = 'OLIGOTROPHIC',
    MESOTROPHIC = 'MESOTROPHIC',
    EUTROPHIC = 'EUTROPHIC',
    SUPEREUTROPHIC = 'SUPEREUTROPHIC',
    HYPEREUTROPHIC = 'HYPEREUTROPHIC',
}

export enum IETLabelEnum {
    ULTRAOLIGOTROPHIC = 'Ultraoligotrófico',
    OLIGOTROPHIC = 'Oligotrófico',
    MESOTROPHIC = 'Mesotrófico',
    EUTROPHIC = 'Eutrófico',
    SUPEREUTROPHIC = 'Supereutrófico',
    HYPEREUTROPHIC = 'Hipereutrófico',
}
