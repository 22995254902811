import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { IQAChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IQAChartRequestDTO'
import { FilterIETChartCP } from 'modules/reports/components/filters/filter-iet-chart/FilterIETChartCP'
import { IETChartFiltersFormModel } from 'modules/reports/components/filters/filter-iet-chart/inner/IETChartFiltersFormModel'
import { TableIETChartCP } from 'modules/reports/components/tables/table-iet-chart/TableIETChartCP'
import { IETChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/IETChartResponseDTO'
import { IETChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/IETChartRequestDTO'

/**
 */
export function ScreenContentChartIET(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<IETChartFiltersFormModel>(new IETChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<IETChartResponseDTO>()

    const [chartRequestDTO, setChartRequestDTO] = useState<IETChartRequestDTO>()
    const getChartRequest = useRequest<IETChartResponseDTO>()
    useEffect(onGetChartRequestChange, [getChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => { filter() }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: IQAChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new IETChartFiltersFormModel({
                locationCodes: queryFilters?.locationCodes,
                dateRange: queryFilters?.dateRange,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const filters: IQAChartRequestDTO = {
            locationCodes: formValues.locationCodes,
            dateRange: formValues.dateRange,
        }
        getChartRequest.runRequest(ChartsRequests.getIETChart(filters))
        setLoadedData(undefined)
        setChartRequestDTO(filters)
    }

    /**
     */
    function onGetChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Índice de Estado Trófico (IET)'}/>
            }
            content={
                <>
                    <FilterIETChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!chartRequestDTO &&
                                <TableIETChartCP
                                    data={loadedData.ietTableItens}
                                    filters={chartRequestDTO}
                                />
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
